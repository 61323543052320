<template>
    <span @mouseleave="isTooltipVisible = false" class="info-tooltip-wrapper" :class="[bottom && 'bottom-tooltip']">
        <div v-show="isTooltipVisible" class="info-tooltip noto-sans-kr">
            {{ text }}
        </div>
        <div @mouseenter="isTooltipVisible = true" class="info-tooltip-icon">
            <img src="@/assets/images/icons/info-gray.svg" alt="info icon" class="info-icon" />
        </div>
    </span>
</template>

<script>
export default {
    name: "InfoTooltip",
    data() {
        return {
            isTooltipVisible: false,
        };
    },

    props: {
        text: {
            type: String,
            default:
                "Jeweler AI by Willyou.com – an independent tool to effortlessly help you find the perfect diamond engagement ring. No ties to the listed jewelers.",
        },
        bottom: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.info-tooltip-wrapper {
    display: inline-flex;
    position: relative;
    width: 16px;
    height: 16px;

    .info-tooltip {
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 310px;
        padding: 8px;
        background: rgb(29, 33, 49);
        color: rgb(246, 246, 246);
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 1;
        font-size: 12px;
        font-weight: 600;
        text-transform: unset;
    }

    .info-icon {
        height: 16px;
        width: 16px;
    }

    .info-tooltip-icon {
        height: 100%;
        // margin-left: 4px;
    }
}

.bottom-tooltip {
    .info-tooltip {
        z-index: 1099;
        top: 30px;
        bottom: unset;
    }

    @media (min-width: 600px) and (max-width: 680px) {
        .info-tooltip {
            right: -190px;
            left: unset !important;
        }
    }
}
</style>
