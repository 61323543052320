<template>
    <div id="layout">
        <navbar></navbar>
        <div v-if="!isNavbarCollapseOpen">
            <PageNotFound v-if="isShowPageNotFound" />
            <router-view v-else @changeComponentKey="changeComponentKey" :key="currentKey"></router-view>
            <app-footer v-if="!storeMode"></app-footer>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Navbar from "./navbar/Navbar.vue";
import Footer from "./footer/Footer.vue";
import PageNotFound from "../views/errors/PageNotFound.vue";

export default {
    name: "layout",
    components: {
        navbar: Navbar,
        "app-footer": Footer,
        PageNotFound,
    },
    data() {
        return {
            currentKey: 0,
        };
    },
    computed: {
        ...mapState("willyou", ["isNavbarCollapseOpen", "storeMode", "isDiamondsPage", "isShowPageNotFound"]),
    },
    watch: {
        $route(to, from) {
            let basePath = to.fullPath.split("?");
            let params = basePath[1] ? `?${basePath[1]}` : "";
            from.fullPath && basePath[0] !== basePath[0].toLowerCase() ? this.$router.replace(`${basePath[0].toLowerCase()}${params}`) : null;
        },
    },
    methods: {
        ...mapActions("willyou", {
            initRequest: "initRequest",
            replaceBrowserLocation: "replaceBrowserLocation",
        }),
        changeComponentKey() {
            setTimeout(() => {
                this.currentKey++;
            }, 850);
        },
    },
    mounted() {
        this.initRequest();

        // Geolocation was disabled for the meantime
        // this.$getLocation().then(location => {
        //   return location ? this.replaceBrowserLocation(location) : null;
        // });
    },
};
</script>
