<template>
    <div id="page-not-found">
        <section class="page-not-found-section">
            <div class="container text-center">
                <div class="row justify-content-center align-self-center">
                    <div class="col-12 col-lg-5">
                        <img class="zxc mx-auto img-fluid steps-img-md" alt="Start With a Diamond" src="../../../assets/images/home/view-and-pickup3.png" />
                    </div>
                    <div class="col-12 col-lg-5">
                        <div class="box h-100 d-lg-flex justify-content-center flex-column text-lg-left text-md-center">
                            <div class="row">
                                <div class="col-lg col-12 pt-3">
                                    <h6 class="home-subtitle text-uppercase">404 page</h6>
                                    <h1 class="w-100 home-title text-lg-left text-center">
                                        Oops... <br />
                                        Something went wrong.
                                    </h1>
                                    <p class="noto-sans-font-title text-lg-left text-center mw-450-px steps-p float-lg-left float-none">
                                        Willyou try another page? Or may we propose to return to the home page.
                                    </p>
                                </div>
                            </div>
                            <div class="w-100"></div>
                            <router-link
                                aria-label="start with a ring"
                                :to="{ name: 'home' }"
                                class="btn rnd-secondary-btn balance-width float-lg-left float-none mt-2 text-uppercase balance-width"
                                >homepage
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <successfully-loaded-indicator></successfully-loaded-indicator>
        </section>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import SuccessfullyLoadedVue from "../../components/SuccessfullyLoaded.vue";
export default {
    name: "page-not-found",
    components: {
        "successfully-loaded-indicator": SuccessfullyLoadedVue,
    },
    methods: {
        ...mapMutations("willyou", ["SET_IS_SHOW_PAGE_NOT_FOUND"]),
    },

    watch: {
        $route() {
            this.SET_IS_SHOW_PAGE_NOT_FOUND(false);
        },
    },

    mounted() {
        document.title = "Page Not Found - Willyou";
    },
};
</script>

<style lang="scss" scoped>
#page-not-found {
    .page-not-found-section {
        padding-bottom: 131px;
        padding-top: 163px;

        @media (min-width: 1200px) {
            padding-bottom: 80px;
            padding-top: 163px;
        }
    }
    a.balance-width {
        width: max-content;
    }
    .steps-p {
        padding-bottom: 0;
        min-height: 84px;
    }
    .img-floating-text {
        text-shadow: 0 0 14px rgba(0, 0, 0, 0.5);
        font-family: "Satisfy", cursive;
        font-size: 30px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 45%;
        margin: auto;
        z-index: 1;
    }
}
</style>
