<template>
    <div id="footer-desktop" class="d-none d-md-block">
        <footer class="footer page-footer font-small">
            <div v-if="$route.name !== 'minisite'" class="container footer-container text-center text-md-left">
                <div class="row justify-content-center">
                    <div class="col-xl col-lg-3 col-md-3 col-xs-12 col-sm-12 col-xl">
                        <router-link :to="{ name: 'find-a-jeweler-by-state' }">
                            <div class="text-uppercase list-heading text-center">Store Locator</div>
                        </router-link>
                    </div>
                    <!-- <div class="col-xl col-lg-3 col-md-2 col-xs-12 col-sm-12 col-xl-2">
                        <router-link to="/engagement-rings">
                            <div class="text-uppercase list-heading text-center">Engagement Rings</div>
                        </router-link>
                    </div> -->
                    <!-- <div class="col-xl col-lg-2 col-md-2 col-xs-12 col-sm-12 col-xl">
                        <router-link :to="{ name: 'diamonds-page' }">
                            <div class="text-uppercase list-heading text-center">DIAMONDS</div>
                        </router-link>
                    </div> -->
                    <div class="col-xl col-lg-3 col-md-3 col-xs-12 col-sm-12 col-xl">
                        <router-link :to="{ name: 'blog' }">
                            <div class="text-uppercase list-heading text-center">BLOG</div>
                        </router-link>
                    </div>
                    <div class="col-xl col-lg-3 col-md-3 col-xs-12 col-sm-12 col-xl">
                        <router-link :to="{ name: 'about' }">
                            <div class="text-uppercase list-heading text-center">ABOUT</div>
                        </router-link>
                    </div>
                    <!-- <div class="col-xl col-lg-3 col-md-3 col-xs-12 col-sm-12 col-xl-3 social-icons">
                       <social-networks></social-networks>
                    </div> -->
                </div>
            </div>
            <div class="footer-copyright text-center py-3">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-3">
                            <span class="text-left rights-signature">© willyou.net 2025</span>
                        </div>
                        <div class="col-6">
                            <router-link :to="{ name: 'terms-of-use' }">Terms of Use</router-link>
                            <span class="px-2"> | </span>
                            <router-link :to="{ name: 'privacy-policy' }">Privacy Policy</router-link>
                            <span class="px-2"> | </span>
                            <router-link :to="{ name: 'dmca-policy' }">DMCA Policy</router-link>
                        </div>
                        <!-- <div class="col-3">
                            <span class="text-right"><router-link :to="{ name: 'login' }">Jeweler Login</router-link></span>
                        </div> -->
                        <a class="col-3 text-center" href="mailto:support@willyou.net">support@willyou.net</a>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
// import SocialNetworks from "../SocialNetworks.vue";

export default {
    name: "footer-desktop",
    components: {
        // "v-lazy-img": VLazyImage,
        // SocialNetworks,
    },
    computed: {
        getEmailAddress() {
            return this.$route.name === "minisite" ||
                this.$route.name === "more-traffic" ||
                this.$route.name === "minisite-user" ||
                this.$route.name === "more-traffic-user"
                ? "jewelers.support@willyou.net"
                : "marryme@willyou.net";
        },
    },
};
</script>

<style lang="scss" scoped>
.footer {
    font-family: "Noto Sans KR", sans-serif;
    position: relative;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(to top, #ffffff, #f7f7f7);

    .footer-container {
        padding: 30px 0 60px 0;

        .list-heading {
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4a4a4a;
            font-size: 16px;
            margin-top: 30px;
        }

        .footer-list-element {
            padding-top: 30px;

            a {
                color: rgba(74, 74, 74, 0.87);
            }
        }
    }
    .social-icons {
        margin-top: 30px;
    }

    .footer-copyright {
        background-color: #767d98;
        color: #fff;

        .rights-signature {
            color: rgba(255, 255, 255, 0.6);
        }

        a {
            color: #fff;
        }
    }
}
</style>
